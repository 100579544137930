<template>
  <div
    class="w-full bg-primary text-white items-center justify-center text-center p-1"
  >
    <i :class="`mdi mdi-information text-white mr-1`"></i>
    <span class="text-sm mr-1">{{ $t('This Site is not Public yet') }}</span>
    <span class="text-sm"
      >{{ $t('(Search and Browsing are deactivated)') }}
      <BaseButton
        small
        :type="'secondary-text'"
        :label="'Log in to edit'"
        @click="routetoLogin()"
        class="px-1"
    /></span>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  methods: {
    routetoLogin() {
      this.$router.push(this.localePath({ path: '/sites' }))
    },
  },
}
</script>
