<template>
  <div v-if="rsCollection">
    <nav class="app-sitesnavbar shadow-sm">
      <div class="app-sitesnavbar__wrapper px-0">
        <div class="flex w-full items-center justify-between h-[72px]">
          <div class="app-sitesnavbar__block">
            <!-- Brand Logo -->
            <div class="app-sitesnavbar__brand">
              <NuxtLink
                :to="localePath({ path: '/sites/' + url })"
                :title="$t('Home')"
                class="navbar-item"
              >
                <Brand
                  v-if="rsCollection"
                  :rsCollection="rsCollection"
                  :compact="!spaced"
                />
              </NuxtLink>
            </div>
            <h4 class="mb-0 hidden md:flex">{{ rsCollection?.title }}</h4>
          </div>

          <div class="app-sitesnavbar__block">
            <div class="app-sitesnavbar__burger-btn z-20 pr-4">
              <label class="text-2xl cursor-pointer" for="nav-drawer">
                <i
                  :class="[`mdi mdi-menu text-${rsCollection?.config.theme}`]"
                  v-if="!drawerOpen"
                ></i>
                <i
                  :class="[`mdi mdi-close text-${rsCollection?.config.theme}`]"
                  v-else
                ></i>
              </label>
            </div>
            <div class="app-sitesnavbar__block__inner app-sitesnavbar__menu">
              <div class="app-sitesnavbar__item">
                <NuxtLink
                  :to="localePath({ path: '/sites/' + url })"
                  :title="$t('Home')"
                  :class="[
                    {
                      active: $route.path.split('/').slice(-1)[0] === url,
                    },
                    rsCollection?.config.theme,
                  ]"
                  class="app-sitesnavbar__link"
                >
                  <span>
                    {{
                      $translate('Home', language, rsCollection?.locales)
                    }}</span
                  >
                </NuxtLink>
              </div>
              <div class="app-sitesnavbar__item">
                <NuxtLink
                  :to="localePath({ path: '/sites/' + url + '/browse' })"
                  :class="[
                    {
                      active: $route.path.split('/').slice(-1)[0] === 'browse',
                    },
                    rsCollection?.config.theme,
                  ]"
                  class="app-sitesnavbar__link"
                >
                  <span>
                    {{
                      $translate('Explore', language, rsCollection?.locales)
                    }}</span
                  >
                </NuxtLink>
              </div>

              <div class="app-sitesnavbar__item">
                <NuxtLink
                  :to="
                    localePath({
                      path: '/sites/' + url + '/search',
                    })
                  "
                  :class="[
                    {
                      active: $route.path.split('/').slice(-1)[0] === 'search',
                    },
                    rsCollection?.config.theme,
                  ]"
                  class="app-sitesnavbar__link"
                >
                  <span>
                    {{
                      $translate('Search', language, rsCollection?.locales)
                    }}</span
                  >
                </NuxtLink>
              </div>

              <div
                class="flex items-center px-4 font-semibold text-secondaryG-700"
              >
                <div
                  class="dropdown dropdown-end"
                  v-if="rsCollection?.config?.additionalPages?.length > 0"
                >
                  <div tabindex="0" role="button">
                    {{ $translate('Content', language, rsCollection?.locales) }}
                  </div>
                  <ul
                    tabindex="0"
                    class="menu dropdown-content z-[1] p-2 shadow bg-base-100 rounded-box w-52 mt-4"
                  >
                    <li>
                      <a
                        href="#"
                        @click.prevent="
                          $router.push(
                            localePath({
                              path: '/sites/' + url + '/about',
                            })
                          )
                        "
                      >
                        {{
                          $translate('About', language, rsCollection?.locales)
                        }}
                      </a>
                    </li>
                    <li
                      v-for="(site, i) in rsCollection?.config.additionalPages"
                      :key="`Site${i}`"
                    >
                      <a
                        v-show="site.show"
                        href="#"
                        @click.prevent="
                          $router.push(
                            localePath({
                              path: '/sites/' + url + '/content/' + site.label,
                            })
                          )
                        "
                      >
                        {{
                          $translate(
                            site.label,
                            language,
                            rsCollection?.locales
                          )
                        }}
                      </a>
                    </li>
                  </ul>
                </div>
                <NuxtLink
                  v-else
                  :to="
                    localePath({
                      path: '/sites/' + url + '/about',
                    })
                  "
                  :class="[
                    {
                      active: $route.path.split('/').slice(-1)[0] === 'about',
                    },
                    rsCollection?.config.theme,
                  ]"
                  class="app-sitesnavbar__link"
                >
                  <span>
                    {{
                      $translate('About', language, rsCollection?.locales)
                    }}</span
                  >
                </NuxtLink>
              </div>
              <div
                class="flex items-center px-4 font-semibold text-secondaryG-700"
              >
                <div class="dropdown dropdown-end">
                  <div tabindex="0" role="button" class="flex">
                    <i :class="'mdi mdi-web'"></i>
                    {{ language?.toUpperCase() }}
                  </div>
                  <ul
                    tabindex="0"
                    class="menu dropdown-content z-[1] p-2 shadow bg-base-100 rounded-box w-52 mt-2"
                  >
                    <li
                      v-for="(lang, i) in rsCollection?.locales"
                      :key="`Lang${i}`"
                    >
                      <a href="#" @click.prevent="setLanguage(lang.code)">
                        {{ lang.code.toUpperCase() }}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="drawer drawer-end fixed top-0 bottom-0"
          :class="drawerOpen ? 'pointer-events-auto' : 'pointer-events-none'"
        >
          <input
            id="nav-drawer"
            type="checkbox"
            class="app-sitesnavbar__drawer__toggle"
            v-model="drawerOpen"
          />
          <div class="drawer-side">
            <label for="nav-drawer" class="drawer-overlay"></label>
            <ul class="app-sitesnavbar__drawer__menu !pt-20">
              <li class="app-sitesnavbar__drawer__menu__item">
                <NuxtLink
                  :to="localePath({ path: '/sites/' + url })"
                  :title="$t('Home')"
                  :class="[
                    {
                      active: $route.path.split('/').slice(-1)[0] === url,
                    },
                    rsCollection?.config.theme,
                  ]"
                  class="app-sitesnavbar__drawer__menu__link"
                >
                  <span>
                    {{
                      $translate('Home', language, rsCollection?.locales)
                    }}</span
                  >
                </NuxtLink>
              </li>
              <li class="app-sitesnavbar__drawer__menu__item">
                <NuxtLink
                  :to="localePath({ path: '/sites/' + url + '/browse' })"
                  :class="[
                    {
                      active: $route.path.split('/').slice(-1)[0] === 'browse',
                    },
                    rsCollection?.config.theme,
                  ]"
                  class="app-sitesnavbar__drawer__menu__link"
                >
                  <span>
                    {{
                      $translate('Explore', language, rsCollection?.locales)
                    }}</span
                  >
                </NuxtLink>
              </li>
              <li class="app-sitesnavbar__drawer__menu__item">
                <NuxtLink
                  :to="
                    localePath({
                      path: '/sites/' + url + '/search',
                    })
                  "
                  :class="[
                    {
                      active: $route.path.split('/').slice(-1)[0] === 'search',
                    },
                    rsCollection?.config.theme,
                  ]"
                  class="app-sitesnavbar__drawer__menu__link"
                >
                  <span>
                    {{
                      $translate('Search', language, rsCollection?.locales)
                    }}</span
                  >
                </NuxtLink>
              </li>

              <li
                class="flex items-center font-semibold text-secondaryG-700 flex-row"
              >
                <div
                  class="flex items-center px-4 font-semibold text-secondaryG-700"
                  v-if="rsCollection?.config?.additionalPages?.length > 0"
                >
                  <select
                    class="app-select__select pl-1 !text-secondaryG-700"
                    style="box-shadow: none"
                    v-model="selectedValue"
                    @change="navigateTo($event)"
                  >
                    <option disabled value="">
                      {{
                        $translate('Content', language, rsCollection?.locales)
                      }}
                    </option>

                    <option :value="`/sites/${url}/about`">
                      {{ $translate('About', language, rsCollection?.locales) }}
                    </option>

                    <option
                      :value="'/sites/' + url + '/content/' + item.label"
                      v-for="(item, index) in rsCollection?.config
                        ?.additionalPages"
                    >
                      {{
                        $translate(item.label, language, rsCollection?.locales)
                      }}
                    </option>
                  </select>
                </div>
                <NuxtLink
                  v-else
                  :to="
                    localePath({
                      path: '/sites/' + url + '/about',
                    })
                  "
                  :class="[
                    {
                      active: $route.path.split('/').slice(-1)[0] === 'about',
                    },
                    rsCollection?.config.theme,
                  ]"
                  class="app-sitesnavbar__link"
                >
                  <span>
                    {{
                      $translate('About', language, rsCollection?.locales)
                    }}</span
                  >
                </NuxtLink>
              </li>

              <li
                class="flex items-center font-semibold text-secondaryG-700 flex-row"
              >
                <div><i :class="'mdi mdi-web'"></i></div>
                <select
                  @input="setLanguage($event.target.value)"
                  placeholder="Select language"
                  class="app-select__select pl-1 !text-secondaryG-700"
                  style="box-shadow: none; width: auto; padding: 0"
                >
                  <option
                    v-bind:selected="language === lang.code"
                    v-for="(lang, i) in rsCollection?.locales"
                    :key="`Lang${i}`"
                    :value="lang.code"
                  >
                    {{ lang.code.toUpperCase() }}
                  </option>
                </select>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import Brand from '~/components/Sites/NavbarBrand.vue'

import { mapState } from 'pinia'
import { useSiteStore } from '~/stores/sites'
export default {
  setup() {
    const { userProfile } = useKeycloak()
    const store = useSiteStore()

    return {
      userProfile,
      store,
    }
  },
  components: {
    Brand,
  },
  props: {
    spaced: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      drawerOpen: false,
      selectedLang: 'en',
      selectedValue: '',
    }
  },
  watch: {
    selectedLang: {
      handler(value) {
        this.setLanguage(value)
      },
    },
  },

  computed: {
    ...mapState(useSiteStore, ['language', 'rsCollection']),
    url() {
      return this.$route.params.url
    },
  },
  async mounted() {
    // Fetch rsCollection data
    const store = useSiteStore()
    await store.fetchRsCollection(this.url)

    const currentPath = this.$route.path
    if (currentPath === `/sites/${this.url}/about`) {
      this.selectedValue = `/sites/${this.url}/about`
    } else {
      if (this.rsCollection?.config?.additionalPages) {
        for (const site in this.rsCollection?.config?.additionalPages) {
          if (
            site.show &&
            currentPath === `/sites/${this.url}/content/${site.label}`
          ) {
            this.selectedValue = `/sites/${this.url}/content/${site.label}`
            break
          }
        }
      }
    }
  },
  methods: {
    setLanguage(value) {
      if (value) {
        this.store.setLanguage(value)
      }
    },
    navigateTo(event) {
      this.$router.push(this.localePath({ path: event.target.value }))
    },
  },
}
</script>
