<template>
  <div
    class="app-modal__subs"
    style="max-width: 696px; max-height: 696px; padding: 25px"
  >
    <div class="app-modal__header">
      <h3 v-if="isAdmin" class="app-title">
        {{ $t('Admin subscription manager') }}
      </h3>
      <h3 v-else class="app-title">{{ $t('Select subscriptions') }}</h3>

      <p class="mt-3">
        {{
          $t(
            'To publish your site, you need to select a paid subscription. Select one of your active subscriptions or buy a new one '
          )
        }}
        <span class="font-bold text-primary">
          <a href="https://www.transkribus.org/plans/sites" target="_blank">{{
            $t('here.')
          }}</a></span
        >
        {{
          $t(
            ' You can remove or edit site subscriptions later in the settings of this site.'
          )
        }}
      </p>
      <p>
        {{ $t('Your site currently includes ') }}
        <b> {{ pagesTotal }}</b>
        {{ $t(' pages.') }}
      </p>
    </div>

    <div class="app-modal__body--process">
      <!-- <p>
        {{
          $t(
            'To publish your site, you need to select a paid subscription. Select one of your active subscriptions or buy a new one '
          )
        }}
        <span class="font-bold text-primary">
          <a href="https://www.transkribus.org/plans/sites" target="_blank">{{
            $t('here.')
          }}</a></span
        >
        {{
          $t(
            ' You can remove or edit site subscriptions later in the settings of this site.'
          )
        }}
      </p>
      <p>
        {{ $t('Your site currently includes ') }}
        <b> {{ pagesTotal }}</b>
        {{ $t(' pages.') }}
      </p> -->
      <div v-if="currentSubscription">
        <div class="app-radio">
          <ul class="flex-col columns-1 justify-start">
            <SubscriptionItem
              :subscription="currentSubscription"
              :isCurrent="true"
              :pagesTotal="pagesTotal"
              @remove="removeSubscription(currentSubscription)"
            ></SubscriptionItem>
          </ul>
        </div>
      </div>
      <div v-else class="app-radio">
        <ul class="flex-col columns-1 justify-start">
          <div v-for="subscription in subscriptions" :key="subscription.id">
            <SubscriptionItem
              :subscription="subscription"
              :isCurrent="false"
              :pagesTotal="pagesTotal"
              @selected="setSubscipriton(subscription)"
              :isDisabled="subscription.url || pagesTotal > subscription.sites"
            ></SubscriptionItem>
          </div>
        </ul>
      </div>
    </div>
    <div v-if="subscriptions">
      <section class="grid place-items-left">
        <label>
          <input class="peer/showLabel absolute scale-0" type="checkbox" />
          <span class="block max-h-14 overflow-hidden rounded-lg px-4 py-0 border transition-all duration-300 peer-checked/showLabel:max-h-52 peer-checked/showLabel:overflow-scroll">
            <div class="flex h-14 cursor-pointer items-center justify-between font-semibold">
              <b>Disclaimer</b>
              <i class="mdi mdi-chevron-down text-xl"></i>
            </div>
            <p class="mb-2 text-sm">You understand and agree that other users may access the content you upload to your Sites. It is your responsibility to ensure that you own or have obtained the appropriate licensing rights to distribute the material and that it does not infringe the rights of any third party. We accept no liability for any misuse or unauthorised distribution of the content you upload. By clicking "Publish", you represent and warrant that you are in compliance with all intellectual property laws and that you will abide by our Terms and Conditions.</p>
          </span>
        </label>
      </section>
      <div class="justify-start py-3 px-5">
        <input type="checkbox" id="pubDisclaimer" v-model="pubDisclaimerChecked" checked>
        <label class="ml-2" for="pubDisclaimer">I understand and agree with the publication disclaimer.</label>
      </div>
    </div>
    <div class="app-modal__footer--settings bg-gray-100">
      <BaseButton
        :type="'secondary'"
        @click="$emit('close')"
        :label="$t('Cancel')"
      ></BaseButton>
      <BaseButton
        class="ml-3"
        :type="'primary'"
        icon-small
        :disabled="canPublish"
        :icon="'earth'"
        @click="publish()"
        :label="$t('Publish')"
      ></BaseButton>
    </div>
  </div>
</template>

<script>
import SubscriptionItem from '~/components/Sites/SubscriptionItem.vue'

import { mapState } from 'pinia'
import { useSiteStore } from '~/stores/sites'
const sitesApi = useSites()
const subscriptionsApi = useSubscriptions()

export default {
  setup() {
    const { userProfile } = useKeycloak()
    const siteStore = useSiteStore()
    return {
      userProfile,
      siteStore,
    }
  },
  components: {
    SubscriptionItem,
  },
  props: {},

  data() {
    return {
      message: '',
      notification: null,
      subscriptions: null,
      selectedSubscription: null,
      pagesTotal: null,
      pubDisclaimerChecked: false,
    }
  },
  created() {},
  destroyed() {},
  computed: {
    ...mapState(useSiteStore, ['language', 'rsCollection', 'unsavedChanges']),

    docId() {
      return this.$route.params.docid
    },
    userId() {
      return this.userProfile.UserId
    },
    isAdmin() {
      return this.userProfile.IsAdmin
    },
    currentSubscription() {
      return this.subscriptions?.find(
        subscription => subscription.url === this.rsCollection.url
      )
    },
    canPublish(){
      return !this.selectedSubscription || !this.pubDisclaimerChecked;
    }
  },
  watch: {},
  async mounted() {
    this.fetchSubscription()
    this.fetchPages()
    this.loadHubSpotForm()
  },
  methods: {
    loadHubSpotForm() {
      const hubspotScript = document.createElement('script')
      hubspotScript.charset = 'utf-8'
      hubspotScript.type = 'text/javascript'
      hubspotScript.src = '//js-eu1.hsforms.net/forms/embed/v2.js'
      document.head.appendChild(hubspotScript)

      hubspotScript.addEventListener('load', () => {
        if (typeof hbspt !== 'undefined' && hbspt.forms) {
          hbspt.forms.create({
            region: 'eu1',
            portalId: '25701986',
            formId: '8f0b186a-a238-4c98-afba-487802dcdd7c',
            target: '#hubspotForm', // The form's ID,
          })
        }
      })
    },
    setSubscipriton(subscription) {
      this.selectedSubscription = subscription
    },
    async removeSubscription(subscription) {
      let sitesData = await sitesApi.fetchSite({ url: subscription.url })

      let data = await sitesApi.updateSiteStatus({
        customUrl: sitesData.url,
        status: 'Draft',
      })

      let rsCollectionTemp = JSON.parse(JSON.stringify(sitesData))
      rsCollectionTemp.subscriptionId = null
      let sites = await sitesApi.updateRsLiteCollection({
        sites: rsCollectionTemp,
        customUrl: rsCollectionTemp.url,
      })

      await subscriptionsApi.setSitesSubscriptionUrl({
        subscriptionId: subscription.subscriptionId,
        url: null,
      })
      this.fetchSubscription()
      await this.siteStore.updateSites(this.rsCollection.url)
    },
    async publish() {
      if (this.selectedSubscription) {
        let rsCollectionTemp = JSON.parse(JSON.stringify(this.rsCollection))
        rsCollectionTemp.subscriptionId =
          this.selectedSubscription.subscriptionId

        await sitesApi.updateRsLiteCollection({
          sites: rsCollectionTemp,
          customUrl: rsCollectionTemp.url,
        })
        let data = await sitesApi.updateSiteStatus({
          customUrl: this.rsCollection.url,
          status: 'Published',
        })

        // TODO: update url in subscription
        await subscriptionsApi.setSitesSubscriptionUrl({
          subscriptionId: this.selectedSubscription.subscriptionId,
          url: this.rsCollection.url,
        })
        this.fetchSubscription()
        this.selectedSubscription = null
        await this.siteStore.updateSites(this.rsCollection.url)
      } else {
        console.log('No subscription selected')
      }
    },
    async fetchPages() {
      let data = await sitesApi.fetchSitesPageCount({
        collections: this.rsCollection.colId,
      })

      if (data) {
        this.pagesTotal = data.total
      }
    },

    async fetchSubscription() {
      let user = this.userId
      if (this.isAdmin) {
        user = this.rsCollection.userId
      }
      const data = await subscriptionsApi.getSitesSubscription({ userId: user })

      if (data) {
        this.subscriptions = data
      }
    },
    
  },
}
</script>

<style scoped>
.modal-box {
  max-width: 696px !important;
}
</style>
