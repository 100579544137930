<template>
    <div class="app-graphicselect">
        <ul class="flex-col columns-2 justify-start">
            <li v-for="(option, index) in options">
                <input 
                    type="radio" 
                    :id="option.name" 
                    name="themeChoice" 
                    :value="option.value" 
                    class="hidden peer" 
                    required
                >
                <label 
                    :for="option.name" 
                    class="app-graphicselect__input"
                    :class="{'checked': rsCollection?.config.theme === option.value}"
                >                           
                    <div class="flex items-center">
                        <div class="flex-shrink-0 h-6 w-6 rounded-full" :style="{backgroundColor: option.colour}"></div>
                        <div class="w-full text-base pl-4">{{ option.name }}</div>
                    </div>
                </label>
            </li>
        </ul>
    </div>

</template>
  
<script>
import { mapState } from 'pinia'
import { useSiteStore } from '~/stores/sites'
import { createMapper } from '~/utils/mapping.js'

const mapper = createMapper('browser')

export default {
    name: 'GraphicSelect',
    props: {
        options: Array,
        type: String,
    },
    computed: {
        ...mapState(useSiteStore, ['language', 'rsCollection']),
    }
}
</script>
  