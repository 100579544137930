<template>
  <footer class="app-sitesfooter p-4" v-if="rsCollection">
    <div
      v-if="rsCollection?.config.orglink !== ''"
      class="!items-center grid-flow-col"
    >
      <a :href="rsCollection?.config.orglink" target="_blank">
        <Brand />
      </a>
      <p class="ml-5 mb-0">
        <a
          :href="rsCollection?.config.orglink"
          target="_blank"
          class="app-sitesfooter__link"
          :class="rsCollection?.config.theme"
        >
          {{ rsCollection?.config.orglinktitle }}
        </a>
      </p>
    </div>
    <div v-else class="!items-center grid-flow-col">
      <Brand />
    </div>

    <div class="grid-flow-col gap-4 md:place-self-center md:justify-self-end">
      <div>
        <nuxt-link
          :to="localePath({ path: '/sites/' + url + '/imprint' })"
          class="app-sitesfooter__link"
          :class="rsCollection?.config.theme"
        >
          {{ $translate('Imprint', language, rsCollection.locales) }}
        </nuxt-link>
      </div>
      <p class="mb-0">
        <a
          href="https://readcoop.eu/privacy-policy/"
          target="_blank"
          class="app-sitesfooter__link"
          :class="rsCollection?.config.theme"
        >
          {{ $translate('Privacy', language, rsCollection.locales) }}
        </a>
      </p>
    </div>
  </footer>
</template>

<style>
.footer-logo {
  height: 3rem;
}

.footer {
  padding: 1.5rem 1.5rem 1.5rem !important;
}

.footer-divider {
  margin: 0 1rem;
  width: 0.075rem;
  height: 4rem;
  background-color: white;
}
</style>

<script>
import Brand from '~/components/Sites/NavbarBrand.vue'
import { mapState } from 'pinia'
import { useSiteStore } from '~/stores/sites'
export default {
  components: {
    Brand,
  },
  computed: {
    ...mapState(useSiteStore, ['language', 'rsCollection']),
    url() {
      return this.$route.params.url
    },
    collId() {
      return this.$route.params.collectionid
    },
  },
}
</script>
