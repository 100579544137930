<template>
  <div v-if="editor" class="bg-white rounded text-gray-500 p-5">
    <BaseButton
      small
      :type="'icon'"
      :icon="'format-align-left'"
      class="ml-1 border-none"
      :active="editor.isActive({ textAlign: 'left' })"
      @click="editor.chain().focus().setTextAlign('left').run()"
    ></BaseButton>

    <BaseButton
      small
      :type="'icon'"
      :icon="'format-align-center'"
      class="ml-1 border-none"
      :active="editor.isActive({ textAlign: 'center' })"
      @click="editor.chain().focus().setTextAlign('center').run()"
    ></BaseButton>

    <BaseButton
      small
      :type="'icon'"
      :icon="'format-align-right'"
      :active="editor.isActive({ textAlign: 'right' })"
      class="ml-1 border-none"
      @click="editor.chain().focus().setTextAlign('right').run()"
    ></BaseButton>

    <BaseButton
      small
      :type="'icon'"
      class="ml-3 border-none"
      :icon="'format-bold'"
      :active="editor.isActive('bold')"
      @click="editor.chain().focus().toggleBold().run()"
    ></BaseButton>

    <BaseButton
      small
      :type="'icon'"
      :icon="'format-underline'"
      :active="editor.isActive('underline')"
      class="ml-1 border-none"
      @click="editor.chain().focus().toggleUnderline().run()"
    ></BaseButton>

    <BaseButton
      small
      :type="'icon'"
      :icon="'format-italic'"
      class="ml-1 border-none"
      :active="editor.isActive('italic')"
      @click="editor.chain().focus().toggleItalic().run()"
    ></BaseButton>

    <BaseButton
      small
      :type="'icon'"
      :icon="'format-strikethrough'"
      :active="editor.isActive('strike')"
      class="ml-1 border-none"
      @click="editor.chain().focus().toggleStrike().run()"
    ></BaseButton>

    <BaseButton
      small
      :type="'icon'"
      :icon="'format-paragraph'"
      class="ml-3 border-none"
      :active="editor.isActive('paragraph')"
      @click="editor.chain().focus().setParagraph().run()"
    ></BaseButton>

    <BaseButton
      small
      :type="'icon'"
      :icon="'format-header-1'"
      :active="editor.isActive('heading', { level: 1 })"
      class="ml-1 border-none"
      @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
    ></BaseButton>

    <BaseButton
      small
      :type="'icon'"
      :icon="'format-header-2'"
      :active="editor.isActive('heading', { level: 2 })"
      class="ml-1 border-none"
      @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
    ></BaseButton>

    <BaseButton
      small
      :type="'icon'"
      :icon="'format-header-3'"
      :active="editor.isActive('heading', { level: 3 })"
      class="ml-1 border-none"
      @click="editor.chain().focus().toggleHeading({ level: 3 }).run()"
    ></BaseButton>

    <BaseButton
      small
      :type="'icon'"
      :icon="'format-list-bulleted'"
      :active="editor.isActive('bulletList')"
      class="ml-3 border-none"
      @click="editor.chain().focus().toggleBulletList().run()"
    ></BaseButton>

    <BaseButton
      small
      :type="'icon'"
      :icon="'format-list-numbered'"
      :active="editor.isActive('orderedList')"
      class="ml-1 border-none"
      @click="editor.chain().focus().toggleOrderedList().run()"
    ></BaseButton>

    <BaseButton
      small
      :type="'icon'"
      :icon="'undo'"
      class="ml-3 border-none"
      @click="editor.chain().focus().undo().run()"
    ></BaseButton>

    <BaseButton
      small
      :type="'icon'"
      :icon="'redo'"
      class="ml-1 border-none"
      @click="editor.chain().focus().redo().run()"
    ></BaseButton>

    <BaseButton
      small
      :type="'icon'"
      :icon="'link-variant'"
      :active="editor.isActive('link')"
      class="ml-3 border-none"
      @click="setLink"
    ></BaseButton>

    <BaseButton
      small
      :type="'icon'"
      :icon="'link-variant-off'"
      :disabled="!editor.isActive('link')"
      :class="!editor.isActive('link') ? 'disabled' : ''"
      class="ml-1 border-none"
      @click="editor.chain().focus().unsetLink().run()"
    ></BaseButton>

    <BaseButton
      small
      :type="'icon'"
      :icon="'table'"
      class="ml-1 border-none"
      @click="
        editor
          .chain()
          .focus()
          .insertTable({ rows: 3, cols: 3, withHeaderRow: true })
          .run()
      "
    ></BaseButton>
    <BaseButton
      small
      :type="'icon'"
      :icon="'table-remove'"
      class="ml-1 border-none"
      v-if="editor.isActive('table')"
      @click="editor.chain().focus().deleteTable().run()"
    ></BaseButton>
    <div
      :class="[
        'flex flex-col max-h-0 overflow-hidden opacity-0 transition-all duration-300 ease-in-out',
        { 'max-h-[200px] opacity-100': editor.isActive('table') },
      ]"
    >
      <!-- col + row -->
      <div class="inlinle-flex">
        <!-- col -->
        <BaseButton
          small
          :type="'icon'"
          :icon="'table-column-plus-before'"
          class="ml-1 border-none"
          @click="editor.chain().focus().addColumnBefore().run()"
        ></BaseButton>
        <BaseButton
          small
          :type="'icon'"
          :icon="'table-column-plus-after'"
          class="ml-1 border-none"
          @click="editor.chain().focus().addColumnAfter().run()"
        ></BaseButton>
        <BaseButton
          small
          :type="'icon'"
          :icon="'table-column-remove'"
          class="ml-1 border-none"
          @click="editor.chain().focus().deleteColumn().run()"
        ></BaseButton>
        <!-- row -->
        <BaseButton
          small
          :type="'icon'"
          :icon="'table-row-plus-before'"
          class="ml-1 border-none"
          @click="editor.chain().focus().addRowBefore().run()"
        ></BaseButton>
        <BaseButton
          small
          :type="'icon'"
          :icon="'table-row-plus-after'"
          class="ml-1 border-none"
          @click="editor.chain().focus().addRowAfter().run()"
        ></BaseButton>
        <BaseButton
          small
          :type="'icon'"
          :icon="'table-row-remove'"
          class="ml-1 border-none"
          @click="editor.chain().focus().deleteRow().run()"
        ></BaseButton>
      </div>
    </div>

    <hr class="mb-8 mt-4 border-gray opacity-50" />
    <editor-content :editor="editor" />
  </div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-3'
import Placeholder from '@tiptap/extension-placeholder'
import StarterKit from '@tiptap/starter-kit'
import Link from '@tiptap/extension-link'
import TextAlign from '@tiptap/extension-text-align'
import Underline from '@tiptap/extension-underline'
import OrderedList from '@tiptap/extension-ordered-list'
import ListItem from '@tiptap/extension-list-item'
import Document from '@tiptap/extension-document'
import Heading from '@tiptap/extension-heading'
import Paragraph from '@tiptap/extension-paragraph'
import Text from '@tiptap/extension-text'
import Table from '@tiptap/extension-table'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'
import TableRow from '@tiptap/extension-table-row'
import { mapState } from 'pinia'
import { useSiteStore } from '~/stores/sites'

export default {
  components: {
    EditorContent,
  },
  computed: {
    ...mapState(useSiteStore, ['language', 'rsCollection']),
  },
  data() {
    return {
      editor: null,
    }
  },
  props: {
    content: {
      type: String,
    },
    showSave: {
      type: Boolean,
      default: true,
    },
  },

  mounted() {
    let that = this
    this.editor = new Editor({
      content: this.content,
      extensions: [
        StarterKit,
        Document,
        Paragraph,
        Heading,
        Text,
        Underline,
        OrderedList,
        ListItem,
        TextAlign.configure({
          types: ['heading', 'paragraph'],
          alignments: ['left', 'center', 'right'],
        }),
        Link.configure({
          HTMLAttributes: { target: '_blank', class: 'themeoriginal' },
          linkOnPaste: false,
          openOnClick: true,
        }),
        Placeholder.configure({
          placeholder: that.$t('Write something …'),
        }),
        OrderedList.configure({
          itemTypeName: 'listItem',
          keepMarks: true,
          keepAttributes: true,
        }),
        Table.configure({
          resizable: true,
          HTMLAttributes: {
            class: 'table w-auto mt-10 overflow-x-auto !flex justify-center',
          },
        }),
        TableRow,
        TableHeader,
        TableCell,
      ],
      onUpdate({ editor }) {
        const html = editor.getHTML()
        that.$emit('inputHtml', html)
      },
    })
  },
  watch: {
    content(value) {},
  },
  methods: {
    saveDescription() {
      const html = this.editor.getHTML()
      this.$emit('saveHtml', html)
    },
    setLink() {
      const previousUrl = this.editor.getAttributes('link').href
      const url = window.prompt('URL', previousUrl)

      // cancelled
      if (url === null) {
        return
      }

      // empty
      if (url === '') {
        this.editor.chain().focus().extendMarkRange('link').unsetLink().run()

        return
      }

      // update link
      this.editor
        .chain()
        .focus()
        .extendMarkRange('link')
        .setLink({ href: url })
        .run()
    },
  },
  beforeDestroy() {
    this.editor?.destroy()
  },
  beforeUnmount() {
    this.editor.destroy()
  },
}
</script>
<style scoped>
.active {
  border: 2px solid black;
  background-color: white !important;
}
.disabled {
  background-color: white !important;
}
</style>
<style lang="scss">
/* Basic editor styles */
.ProseMirror {
  > * + * {
    margin-top: 0.75em;
  }
}

/* Placeholder (at the top) */
.ProseMirror p.is-editor-empty:first-child::before {
  content: attr(data-placeholder);
  float: left;
  color: #adb5bd;
  pointer-events: none;
  height: 0;
}

/* Placeholder (on every new line) */
/*.ProseMirror p.is-empty::before {
  content: attr(data-placeholder);
  float: left;
  color: #adb5bd;
  pointer-events: none;
  height: 0;
}*/
</style>
<style lang="scss">
/* .ProseMirror  */
.ProseMirror h1,
.editorStyle h1 {
  display: block;
  font-size: 2em;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}
/* .ProseMirror  */
.ProseMirror h2,
.editorStyle h2 {
  display: block;
  font-size: 1.5em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}
/* .ProseMirror  */
.ProseMirror h3,
.editorStyle h3 {
  display: block;
  font-size: 1.17em;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

.ProseMirror li,
.editorStyle li {
  display: list-item;
  text-align: -webkit-match-parent;
}
/* .ProseMirror  */
.ProseMirror ul,
.editorStyle ul {
  padding: 0 2rem;
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}
/* .ProseMirror  */
.ProseMirror ol,
.editorStyle ol {
  padding: 0 2rem;
  display: block;
  list-style-type: decimal;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}

.ProseMirror a,
.editorStyle a {
  &.none {
    @apply font-semibold text-themeoriginal hover:text-themeoriginal-100;
  }
  &.themeoriginal {
    @apply font-semibold text-themeoriginal hover:text-themeoriginal-100;
  }
  &.themewolpi {
    @apply font-semibold text-themewolpi hover:text-themewolpi-100;
  }
  &.themeautumnal {
    @apply font-semibold text-themeautumnal hover:text-themeautumnal-100;
  }
  &.themeforest {
    @apply font-semibold text-themeforest hover:text-themeforest-100;
  }
  &.themelagoon {
    @apply font-semibold text-themelagoon hover:text-themelagoon-100;
  }
  &.themerose {
    @apply font-semibold text-themerose hover:text-themerose-100;
  }
}

.ProseMirror address,
.editorStyle address {
  font-style: inherit;
}
</style>
