<template>
  <div>
    <TailwindComponentsCommandPalette
      :items="facets"
      :initialSelected="initFilter"
      :selectable="true"
      :customList="true"
      :icon="'filter'"
      :objValue="'name'"
      @selected="(option) => setFilter(option)"
    >
      <template v-slot:selected-item="{ selectedItem }">
        <div class=" pr-6">
         {{ $t(selectedItem?.value) }}
        </div>
      </template>
      <template v-slot:item="{ item }">
        <div class="app-command-palette__content">
          <div class="app-command-palette__content__text">
            {{ $t(item.value) }} 
          </div>
        </div>
      </template>
    </TailwindComponentsCommandPalette>
  </div>
</template>

<script>
export default {
  data() {
    return {
      filterColl: [],
      facets: [
        { value: 'Genre', name: 'f_genre' },
        { value: 'Script Type', name: 'scriptType' },
        { value: 'Author', name: 'f_author' },
        { value: 'External ID', name: 'f_extId' },
        { value: 'Hierarchy', name: 'f_hierarchy' },
        { value: 'Writer', name: 'f_writer' },
        { value: 'Collection', name: 'colId'}
      ],
    }
  },
  props: {
    collId: {
      type: Number,
    },
    propFacet: {
      type: Array,
    },
    initFilter: {
      type: Array,
    },
    maxtags: {
      type: Number,
      default: 10,
    },
  },
  watch: {
    filterColl: {
      handler(value) {
        if (value) {
          this.$emit('filter', value)
        }
      },
    },
  },
  async mounted() {
    if (this.propFacet) {
      this.facets = this.propFacet
    }
    if (this.initFilter) {
      this.filterColl = this.initFilter
    }
  },
  methods: {
    setFilter(value) {
      this.filterColl = value
    },
    emitRemoved() {
      // this.load()
      this.$emit('removedTag', 'wasRemoved')
    },
    addTag(value) {
      let added = value[value.length - 1]
      if (typeof added === 'string' || added instanceof String) {
        value[value.length - 1] = {
          value: added,
          name: added,
        }
      }
    },
  },
}
</script>
