<template>
  <div class="app-slide-over__wrapper">
    <div v-if="showHeader" class="app-slide-over__header">
      <h3 class="app-title">{{ $t('File Upload') }} {{ selectedOption }}</h3>
    </div>
    <div class="app-slide-over__body">
      <form class="mt-2" id="submit-form" v-on:submit.prevent>
        <file-pond
          :ref="`pond${selectedOption}`"
          type="file"
          accepted-file-types="image/jpeg, image/png, image"
          class="filepond"
          name="filepond"
          data-allow-reorder="true"
          data-max-file-size="50MB"
          data-max-files="1"
          v-on:addfilestart="uploadLoading = true"
          v-on:addfile="uploadLoading = false"
        />
      </form>
    </div>
    <div class="app-slide-over__footer">
      <BaseButton
        :label="$t('Submit')"
        :type="'primary'"
        @click="submit"
        :loading="uploadLoading"
        native-type="submit"
      ></BaseButton>
    </div>
  </div>
</template>

<script>
import vueFilePond from 'vue-filepond'

import 'filepond/dist/filepond.min.css'

import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size'

const sitesApi = useSites()

import { mapState } from 'pinia'
import { useSiteStore } from '~/stores/sites'
// Create component
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginFileValidateSize,
  FilePondPluginImagePreview
)
export default {
  components: {
    FilePond,
  },
  computed: {
    ...mapState(useSiteStore, ['rsCollection', 'language']),
    collId() {
      return this.$route.params.collectionid
    },
    userId() {
      return this.$auth?.user?.trp_user_id
    },
  },
  props: {
    fileName: {
      type: String,
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      selectedOption: 'background',
      options: ['background', 'logo', 'about'],
      uploadLoading: false,
    }
  },
  mounted() {
    if (this.fileName) {
      this.options.push(this.fileName)
      this.selectedOption = this.fileName
    }
  },
  methods: {
    async submit() {
      this.uploadLoading = true
      let files = this.$refs[`pond${this.selectedOption}`].getFiles()
      const formData = new FormData()
      formData.append('img', files[0].file)
      try {
        let response = await sitesApi.uploadReadSearchImage({
          data: files[0].file,
          customUrl: this.rsCollection.url,
          type: this.selectedOption,
        })

        const notification = {
          message: this.$t('Image was uploaded!'),
          type: 'success',
        }
        this.$bus.emit('notification', notification)
        this.$forceUpdate()
        window.location.reload(true)
      } catch (err) {
        console.log(err)
        this.$bus.emit('notification', {
          message: this.$t('Could not upload image'),
          type: 'error',
        })
      }
    },
    addOption() {
      const newOption = `image-${this.options.length + 1}`
      this.options.push(newOption)
      this.selectedOption = newOption
    },
  },
}
</script>
