<template>
  <div class="app-modal__box">
    <div class="app-modal__header contents gap-x-4 gap-y-4">
      <h3 class="app-title">{{ $t('Invite contributors') }}</h3>
    </div>
    <div class="flex flex-col gap-4">
      <div class="gap-2 flex flex-col">
        <div v-for="(member, index) in members">
          <div
            class="flex gap-4"
            :class="[
              { 'items-start': member.error },
              { 'items-end': !member.error },
            ]"
          >
            <BaseInput
              :label="index === 0 ? 'Email address' : null"
              :placeholder="'Enter email address'"
              :error="member.error"
              :errorText="'Please enter a valid email address.'"
              :value="member.email"
              @input="handleEmailInput($event.target.value, member)"
            ></BaseInput>
            <BaseButton
              v-if="members.length > 1"
              medium
              :class="{ 'm-auto': index === 0 && member.error }"
              :type="'text'"
              :symbol="'delete'"
              @click="members.splice(members.indexOf(member), 1)"
            />
          </div>
        </div>
      </div>
      <div>
        <BaseButton
          :label="'Add email address'"
          :type="'text'"
          :symbol="'add'"
          @click="addEmail()"
        />
      </div>
      <BaseTextarea
        :label="'Message (optional)'"
        :placeholder="'Enter a personal message to send with the invitation'"
        :id="'message'"
        :name="'message'"
        :value="message"
        @input="message = $event.target.value"
      />
    </div>
    <div class="app-modal__footer flex gap-4">
      <BaseButton :label="'Cancel'" :type="'secondary'" @click="close()" />
      <BaseButton
        as-label
        :for="'User-Manager'"
        :label="'Send invitation'"
        @click="sendInvitation()"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { debounce } from '~/utils/misc.js'
const permissions = usePermissions()
const invitationsApi = useInvitations()
const { $bus } = useNuxtApp()

const props = defineProps<{
  siteId: number
}>()

type Invitation = {
  email: string
  role: string
  error: boolean
  inputError: string
  message: string
}

const members = ref<Array<Invitation>>([
  {
    email: '',
    role: ROLE_SITE_CONTRIBUTOR,
    error: false,
    inputError: '',
    message: '',
  } as Invitation,
])
const message = ref('')

const validateEmail = user => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  user.error = !emailRegex.test(user.email)
  if (user.error) {
    user.inputError = 'Invalid email address'
  }
}

const validate = debounce(member => {
  validateEmail(member)
}, 1000)

const handleEmailInput = (value, member) => {
  member.email = value
  validate(member)
}

const addEmail = () => {
  members.value.push({
    email: '',
    role: ROLE_SITE_CONTRIBUTOR,
    error: false,
    inputError: '',
    message: '',
  } as Invitation)
}

const sendInvitation = async () => {
  members.value.forEach(member => validateEmail(member))
  if (members.value.some(member => member.error)) {
    return
  } else {
    let error = 0
    for (const member of members.value) {
      try {
        await invitationsApi.sendSitesInvitation({
          sitesId: props.siteId,
          inviteeEmail: member.email,
          role: ROLE_SITE_CONTRIBUTOR,
          message: message.value,
        })
      } catch (err) {
        error++
        $bus.emit('notification', {
          message: member.email + ': ' + err.data,
          type: 'error',
        })
      }
    }
    let successfulInvitations = members.value.length - error
    if (successfulInvitations > 0) {
      $bus.emit('notification', {
        message:
          successfulInvitations +
          ' ' +
          (successfulInvitations > 1 ? 'invitations' : 'invitation') +
          ' ' +
          'sent successfully',
        type: 'success',
      })
    }

    $bus.emit('reload-members')
    $bus.emit('close-modal')
  }
}

const close = () => {
  $bus.emit('close-modal')
}
</script>

<style></style>
