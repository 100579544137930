<template>
  <li
    :key="subscription.id"
    class="app-radio__input border rounded-md p-2 mb-2"
    :class="isDisabled ? 'opacity-50' : 'cursor-pointer'"
  >
    <input
      v-if="!isCurrent"
      :id="'radio-' + subscription.id"
      type="radio"
      :value="subscription"
      name="bordered-radio"
      :disabled="isDisabled"
      @change="$emit('selected', subscription)"
    />
    <label :for="'radio-' + subscription.id">
      <div class="flex items-center">
        <div class="w-full text-base pl-4">
          <span class="font-bold text-primary" v-if="isCurrent"
            >{{ $t('Using') }}
          </span>
          <span class="font-bold text-primary">
            Sites {{ $t('subscription') }} - {{ getSize(subscription) }}
          </span>
          <br />
          <span class="text-sm whitespace-nowrap">
            {{ $t('Up to ') }} {{ subscription.sites }}
            {{ $t('pages') }} &middot; {{ $t('Starting ') }}
            <BaseDate :value="subscription.updatedAt" /> &middot;
            {{ $t('Expiring ') }}
            <BaseDate :value="subscription.expirationDate" />
          </span>

          <p v-if="pagesTotal > subscription.sites">
            <i class="mdi mdi-information"></i>
            <span class="text-sm italic">
              {{ $t('Your site has too many pages for this subscription') }}
            </span>
          </p>
          <p v-if="subscription.url">
            <i class="mdi mdi-information"></i>
            <span class="text-sm italic">
              {{ $t('This subscription is already used for the site') }}
              <a
                target="_blank"
                :href="`${baseURL}/sites/${subscription.url}`"
                >{{ `${baseURL}/sites/${subscription.url}` }}</a
              >
            </span>
          </p>
          <p v-if="isCurrent">
            <BaseButton
              :icon="'earth-off'"
              :type="'danger'"
              @click="$emit('remove', currentSubscription)"
              :label="$t('Unpublish')"
            ></BaseButton>
          </p>
        </div>
      </div>
    </label>
  </li>
</template>

<script>
export default {
  computed: {
    baseURL() {
      const config = useRuntimeConfig()
      return config.public.REDIRECT_URI
    },
  },
  props: {
    subscription: {
      type: Object,
      required: true,
    },
    isCurrent: {
      type: Boolean,
      default: false,
    },
    pagesTotal: {
      type: Number,
      default: 0,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getSize(subscription) {
      if (subscription.sites <= 10000) {
        return 'Small'
      } else if (subscription.sites <= 100000) {
        return 'Medium'
      } else {
        return 'Large'
      }
    },
  },
}
</script>
<style scoped>
.app-radio input:checked,
.app-radio input[aria-checked='true'] {
  background-color: #4d2f42;
}
</style>
