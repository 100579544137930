<template>
  <div>
    <client-only>
      <TailwindComponentsCommandPalette
        :items="facets"
        :initialSelected="initTags"
        :selectable="true"
        :customList="true"
        :icon="'filter'"
        @selected="setTags"
      >
        <template v-slot:selected-item="{ selectedItem }">
          <div class="pr-6">
            {{ selectedItem.value || selectedItem }}
          </div>
        </template>
        <template v-slot:item="{ item }">
          <div class="app-command-palette__content">
            <div class="app-command-palette__content__text">
              {{ item.value }} ({{ item.count }})
            </div>
          </div>
        </template>
      </TailwindComponentsCommandPalette>
    </client-only>
  </div>
</template>

<script>
import { mapState } from 'pinia'
import { useSiteStore } from '~/stores/sites'

export default {
  setup() {
    const sitesApi = useSites()
    return {
      sitesApi,
    }
  },
  computed: {
    ...mapState(useSiteStore, ['rsCollection', 'language', 'unsavedChanges']),
    url() {
      return this.$route.params.url
    },
  },
  data() {
    return {
      tagsColl: [],
      filteredTagsColl: [],
      facets: [],
    }
  },
  props: {
    collId: {
      type: Array,
    },
    initTags: {
      type: Array,
    },
  },
  watch: {
    tagsColl: {
      handler(value) {
        if (value) {
          this.$emit('tags', value)
        }
      },
    },
    collId: {
      handler(value) {
        if (value) {
          this.load()
        }
      },
    },
  },
  async mounted() {
    await this.load()
    if (this.initTags) {
      let foundTags = this.facets?.filter((t) =>
        this.initTags.includes(t.value)
      )
      this.tagsColl = foundTags?.map((tag) => tag.value)
    }
  },
  methods: {
    emitRemoved() {
      // this.load()
      this.$emit('removedTag', 'wasRemoved')
    },
    setTags(value) {
      this.tagsColl = value
      this.$emit('selected', this.tagsColl)
    },
    async load() {
      const { total, results, facets } = await this.sitesApi.fetchTagResults({
        facets: 'type',
        collections: this.collId,
        url: this.url,
      })
      this.facets = facets.type
    },
  },
}
</script>
