<template>
  <div v-if="translation">
    <BaseInput
      class="mt-5"
      :label="$t('Heading')"
      :id="'title'"
      :name="'title'"
      :placeholder="$t('About this project')"
      :error="headingError"
      :warning="headingLimitReached"
      :maxLength="50"
      :warningText="$t('You have reached the limit of 50 characters')"
      :errorText="
        $t('Please choose a heading with a maximum length of 50 characters')
      "
      :value="translation.title"
      @input="updateTitle($event.target.value)"
    ></BaseInput>
    <div class="flex flex-row mt-5">
      <div class="bg-indigo-300">
        <img
          class="object-cover h-48 w-96"
          :src="getIIIFImage(rsCollectionTemp, 'about') || noDocumentImage"
        />
      </div>
      <div class="flex flex-col w-full">
        <BaseButton
          as-label
          :for="'UploadImages'"
          class="m-2"
          :label="'Upload image'"
          @click="emitUploadSlideOver('about')"
          :type="'primary'"
        ></BaseButton>
        <BaseButton
          :disabled="!getIIIFImage(rsCollectionTemp, 'about')"
          class="m-2"
          :label="'Remove'"
          @click="deleteImage(rsCollectionTemp, 'about')"
          :type="'secondary'"
        ></BaseButton>
      </div>
    </div>
    <div class="mt-5">
      <label class="app-select__label">{{ $t('About section') }}</label>
      <ul class="grid grid-cols-1">
        <draggable
          :modelValue="translation.sections"
          @update:modelValue="updateSections"
          item-key="id"
          id="textContainer"
          draggable=".item"
          :options="{ disabled: isInputFocused }"
        >
          <template #item="{ element: section, index }">
            <li
              :key="index"
              :data-index="section.index"
              class="mt-4 app-card !bg-uilight-100 flex flex-col item"
            >
              <div class="m-5">
                <div class="flex justify-between">
                  <h4 class="mr-2">{{ section.heading }}</h4>
                  <i class="cursor-move mdi mdi-dots-grid text-tertiary"></i>
                </div>
                <label class="app-select__label">{{
                  $t(section.layout)
                }}</label>
                <div class="collapse collapse-arrow border flex-1">
                  <input type="checkbox" />
                  <div class="collapse-title text-primary">
                    <span class="material-symbols-outlined--small mr-2"
                      >edit</span
                    >
                    {{ $t('Edit') }}
                  </div>
                  <div class="collapse-content gap-y-4 !flex flex-col">
                    <BaseInput
                      class="w-full ignore-elements"
                      :label="$t('Section heading')"
                      :id="section.index"
                      :name="section.index"
                      :value="section.heading"
                      @input="
                        updateSectionHeading(section, $event.target.value)
                      "
                      @focus="isInputFocused = true"
                      @blur="isInputFocused = false"
                    ></BaseInput>
                    <div class="app-select mt-3">
                      <BaseSelect
                        :name="'layout'"
                        :id="'layout'"
                        :options="layoutOptions"
                        @selectionChange="updateSectionLayout(section, $event)"
                        :value="section.layout"
                      ></BaseSelect>
                      <label
                        v-if="section.layout !== 'layout-4'"
                        class="app-select__label"
                      >
                        {{ $t('Description') }}
                      </label>
                      <div
                        v-if="section.layout === 'layout-4'"
                        class="flex flex-row mt-5"
                      >
                        <BaseSelect
                          :placeholder="'Connected collection'"
                          :label="$t('Connected collection')"
                          class="w-full"
                          :options="presetColls"
                          @selectionChange="setCollection(section, $event)"
                          :value="section.colId"
                        ></BaseSelect>
                      </div>
                      <tiptap-editor
                        v-else
                        :value="section.content"
                        @inputHtml="updateSectionContent(section, $event)"
                        class="app-site-editor bg-white p-6 rounded text-gray-500"
                        :content="section.content"
                        :showSave="false"
                      />
                    </div>
                    <div
                      class="flex flex-row mt-5"
                      v-if="
                        section.layout !== 'layout-3' &&
                        section.layout !== 'layout-4'
                      "
                    >
                      <div class="bg-indigo-300">
                        <img
                          v-if="section.image !== ''"
                          class="object-cover h-48 w-96"
                          :src="
                            getIIIFImage(rsCollectionTemp, section.image) ||
                            noDocumentImage
                          "
                        />
                        <img
                          v-else
                          class="object-cover h-48 w-96"
                          src="~/assets/images/no-document.png"
                        />
                      </div>
                      <div class="flex flex-col w-full">
                        <BaseButton
                          as-label
                          :for="'UploadImages'"
                          class="m-2"
                          :label="'Upload image'"
                          @click="
                            emitUploadSlideOver(
                              section.heading + '-' + index,
                              section
                            )
                          "
                          :type="'primary'"
                        ></BaseButton>
                        <BaseButton
                          :disabled="
                            !getIIIFImage(rsCollectionTemp, section.image)
                          "
                          class="m-2"
                          :label="'Remove'"
                          @click="deleteImage(rsCollectionTemp, section.image)"
                          :type="'secondary'"
                        ></BaseButton>
                      </div>
                    </div>
                    <BaseButton
                      :label="'Delete section'"
                      :type="'danger'"
                      :symbol="'delete'"
                      @click="deleteSection(section, index)"
                    ></BaseButton>
                  </div>
                </div>
              </div>
            </li>
          </template>
        </draggable>
        <li class="flex mt-5">
          <a
            @click="addSection"
            class="hover:border-blue-500 hover:border-solid hover:bg-white hover:text-blue-500 group w-full flex flex-col items-center justify-center rounded-md border-2 border-dashed border-slate-300 text-sm leading-6 text-slate-900 font-medium py-3"
          >
            <svg
              class="group-hover:text-blue-500 mb-1 text-slate-400"
              width="20"
              height="20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                d="M10 5a1 1 0 0 1 1 1v3h3a1 1 0 1 1 0 2h-3v3a1 1 0 1 1-2 0v-3H6a1 1 0 1 1 0-2h3V6a1 1 0 0 1 1-1Z"
              />
            </svg>
            {{ $t('Add section') }}
          </a>
        </li>
      </ul>
      <hr class="my-8 border-gray opacity-50" />
      <div
        v-if="permissions.sites[rsCollectionTemp.id]?.addPage"
        class="space-y-4"
      >
        <label class="label">
          <span class="font-semibold">{{ $t('Add page') }}</span>
        </label>
        <BaseInput
          :id="'additionalPage'"
          :name="'additionalPage'"
          :label="'Page Name'"
          :value="additionalPage"
          @input="updateAdditionalPage($event.target.value)"
          class="w-full"
        ></BaseInput>
        <BaseButton
          :label="'Add'"
          :icon="'plus'"
          @click="addAdditionalPage"
          class="w-full"
        ></BaseButton>
      </div>
    </div>
  </div>
</template>

<script>
import noDocumentImage from '~/assets/images/no-document.png'
import TiptapEditor from '~/components/Sites/TipTapEditor.vue'
import StarterKit from '@tiptap/starter-kit'
import { Editor, EditorContent } from '@tiptap/vue-3'
import { mapState } from 'pinia'
import { useSiteStore } from '~/stores/sites'
import enJson from '~/locales/sites/en.json'
import draggable from 'vuedraggable'

export default {
  setup() {
    const { userProfile } = useKeycloak()
    const collectionsApi = useCollections()
    const store = useSiteStore()
    const sitesApi = useSites()
    const permissions = usePermissions()
    return {
      userProfile,
      store,
      noDocumentImage,
      sitesApi,
      collectionsApi,
      permissions,
    }
  },
  components: {
    Editor,
    StarterKit,
    EditorContent,
    TiptapEditor,
    draggable,
  },
  props: {
    rsCollectionTemp: {
      type: Object,
      required: true,
    },
    translation: {
      type: Object,
      required: true,
    },
    pageView: {
      type: String,
    },
  },
  computed: {
    ...mapState(useSiteStore, ['language']),
    contentName() {
      return this.$route.params.contentName
    },
    layoutOptions() {
      return [
        { name: this.$t('Image (left) + text'), value: 'layout-1' },
        { name: this.$t('Image (right) + text'), value: 'layout-2' },
        { name: this.$t('Text only'), value: 'layout-3' },
        { name: this.$t('Search form'), value: 'layout-4' },
      ]
    },
  },
  data() {
    return {
      additionalPage: '',
      headingError: false,
      headingLimitReached: false,
      isInputFocused: false,
      presetColls: [],
      selectedColl: null,
    }
  },
  watch: {
    translation: {
      handler(val) {
        if (val === undefined) {
          this.initializeTranslation()
        }
      },
    },
    rsCollectionTemp: {
      handler(val) {
        this.validateHeading()
        val = {}
      },
      deep: true,
    },
  },
  async mounted() {
    await this.loadCollections()
  },
  methods: {
    initializeTranslation() {
      let temp = this.rsCollectionTemp.locales.find(
        obj => obj.code == this.language
      ).translations.additionalPages
      if (!temp) {
        temp = {}
      }
      temp[this.contentName] = enJson.about
      this.rsCollectionTemp.locales.find(
        obj => obj.code == this.language
      ).translations.additionalPages = temp

      this.store.update({
        rsCollection: JSON.parse(JSON.stringify(this.rsCollectionTemp)),
      })
    },
    validateHeading() {
      if (
        this.translation?.title.length > 50 ||
        this.translation?.title.length < 1
      ) {
        this.headingError = true
      } else {
        this.headingError = false
      }
      this.headingLimitReached = this.translation?.title.length === 50
    },
    getIIIFImage(item, type) {
      if (this.contentName) {
        type = this.contentName + '-' + type
      }
      const image = item.images?.find(i => i.type === type)
      return image
        ? `https://s3.readcoop.eu/api-sites-test/${image.name}.${image.extension}`
        : null
    },
    async loadCollections() {
      try {
        for (const collId of this.rsCollectionTemp.colId) {
          const data = await this.collectionsApi.fetchCollectionMeta({ collId })
          this.presetColls.push({ name: data.colName, value: data.colId })
        }
        this.presetColls.push({ name: 'No collection', value: null })
      } catch (err) {
        this.$bus.emit('notification', {
          message: this.$t('Failed to load collections'),
          type: 'danger',
        })
      }
    },

    async deleteImage(item, type) {
      const foundImage = item.images.find(i => i.type === type)
      if (!foundImage) return

      try {
        await this.sitesApi.deleteReadSearchImage({
          fileName: `${foundImage.name}.${foundImage.extension}`,
          customUrl: this.rsCollectionTemp.url,
        })
        const index = item.images.findIndex(i => i.type === type)
        if (index !== -1) {
          item.images.splice(index, 1)
        }
        this.store.update({
          rsCollection: JSON.parse(JSON.stringify(this.rsCollectionTemp)),
        })
        this.$bus.emit('notification', {
          message: this.$t('Deleted image'),
          type: 'success',
        })
        this.$bus.emit('imageReload')
      } catch (err) {
        this.$bus.emit('notification', {
          message: this.$t('Image deletion failed'),
          type: 'danger',
        })
      }
    },
    emitUploadSlideOver(type, section) {
      if (this.contentName) {
        type = `${this.contentName}-${type}`
      }
      this.$emit('upload-slide-over', type, section)
    },
    setCollection(section, value) {
      section.colId = value
      if (!this.rsCollectionTemp.config) {
        this.rsCollectionTemp.config = {}
      }
      if (!this.rsCollectionTemp.config.meta) {
        this.rsCollectionTemp.config.meta = []
      }
      if (!this.rsCollectionTemp.config.meta.includes('colId')) {
        this.rsCollectionTemp.config.meta.push('colId')
      }
    },
    addSection() {
      const section = {
        image: '',
        heading: 'Section heading',
        content:
          'This paragraph contains formatted text which can be customized by the user. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.',
        layout: 'layout-1',
      }

      if (this.pageView === 'about') {
        const aboutTranslations = this.rsCollectionTemp.locales.find(
          obj => obj.code === this.language
        ).translations.about

        if (!aboutTranslations.sections) {
          aboutTranslations.sections = []
        }
        aboutTranslations.sections.push(section)
      } else {
        const additionalPagesTranslations = this.rsCollectionTemp.locales.find(
          obj => obj.code === this.language
        ).translations.additionalPages[this.findContent(this.contentName)]

        if (!additionalPagesTranslations.sections) {
          additionalPagesTranslations.sections = []
        }
        additionalPagesTranslations.sections.push(section)
      }
    },
    deleteSection(section, index) {
      const localData = this.rsCollectionTemp.locales.find(
        obj => obj.code == this.language
      )

      if (this.pageView === 'about') {
        localData.translations.about.sections.splice(index, 1)
      } else {
        localData.translations.additionalPages[
          this.findContent(this.contentName)
        ].sections.splice(index, 1)
      }
    },
    findContent(name) {
      const found = this.rsCollectionTemp.config.additionalPages.find(
        obj => obj.label === name
      )
      return found?.key
    },
    addAdditionalPage() {
      if (!this.rsCollectionTemp.config.additionalPages) {
        this.rsCollectionTemp.config.additionalPages = []
      }
      this.rsCollectionTemp.config.additionalPages.push({
        key: this.additionalPage,
        show: false,
        label: this.additionalPage,
      })

      for (const locale of this.rsCollectionTemp.locales) {
        if (!locale.translations.additionalPages) {
          locale.translations.additionalPages = {}
        }
        locale.translations.additionalPages[this.additionalPage] = enJson.about
      }
    },
    updateTitle(value) {
      this.translation.title = value
    },
    updateSections(value) {
      this.translation.sections = value
    },
    updateSectionHeading(section, value) {
      section.heading = value
    },
    updateSectionLayout(section, value) {
      section.layout = value
    },
    updateSectionContent(section, value) {
      section.content = value
    },
    updateAdditionalPage(value) {
      this.additionalPage = value
    },
  },
}
</script>
